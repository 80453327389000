'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { Suspense, useEffect } from 'react';

function HandleOnCompleteChild() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('pageTransitionEnd'));
  }, [pathname, searchParams]);
  return null;
}

export function HandleNavigationComplete() {
  return (
    <Suspense>
      <HandleOnCompleteChild />
    </Suspense>
  );
}
