"use client";

import { useEffect } from "react";
import { GoogleTagManager, sendGTMEvent } from "@next/third-parties/google";

const Analytics = () => {
  useEffect(() => {
    (window as any).iterationNumber =
      process.env.NEXT_PUBLIC_ITERATION_NUMBER || "-1";

    const onRouteChange = () => {
      sendGTMEvent({
        event: "routeChange",
      });
    };
    onRouteChange();

    window.addEventListener("routeChange", onRouteChange);
    return () => window.removeEventListener("routeChange", onRouteChange);
  }, []);

  return (
    <>
      {process.env.NODE_ENV === "production" && (
        <GoogleTagManager gtmId="GTM-TJ359GWM" />
      )}
    </>
  );
};

export default Analytics;
