import {useEffect} from "react";
import {debounce} from "lodash";

export default function useScroll(
    listener: () => any,
    debounceTime: number = 50
) {
    
    useEffect(() => {
        
        const db = debounce(() => listener(), debounceTime, {maxWait: debounceTime})
        
        window.addEventListener('scroll', db)
        return () => {
            window.removeEventListener('scroll', db)
        }
    }, []);
    
}
