'use client';

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Navigation, {
  MenuState,
} from '@/src/components/layout/navigation/Navigation.tsx';
import { isSSR } from '@/src/lib/utils.ts';
import { parse } from 'qs';
import { MetaRequestContext } from '@/src/lib/meta-request-context.ts';
import Script from 'next/script';
import Analytics from '@/src/components/Analytics.tsx';
import CookieAlert from '@/src/components/CookieAlert.tsx';
import LanguageSuggestion from '@/src/components/layout/LanguageSuggestion.tsx';
import { PostHog } from '@/src/components/PostHog.tsx';

export default function UserLayout({
  initialMenuState,
  initialDarkMode,
  randomSeed,
  initialPageSearch,
  userAgent,
  children,
}: PropsWithChildren<{
  initialMenuState: MenuState;
  initialDarkMode: boolean;
  randomSeed: number;
  initialPageSearch?: string | undefined;
  userAgent?: string | undefined;
}>) {
  const currentSearchQuery = useRef(
    isSSR() ? initialPageSearch : window.location.search,
  );

  return (
    <MetaRequestContext.Provider
      value={{
        userAgent,
        currentSearchQuery: currentSearchQuery.current,
        updateCurrentSearchQuery: (query) => {
          currentSearchQuery.current = query;
        },
        getQuery: function () {
          if (!currentSearchQuery.current) return {};
          return parse(currentSearchQuery.current, {
            ignoreQueryPrefix: true,
          });
        },
      }}
    >
      <Analytics />
      <PostHog />
      <CookieAlert />
      <LanguageSuggestion />
      <Script id="onRouteChange">{`
            (function (history) {
              var pushState = history.pushState;
              history.pushState = function(state){
                var result = pushState.apply(history, arguments);
                window.dispatchEvent(new Event("routeChange", state));
                return result;
              };
            })(window.history);
          `}</Script>
      <Navigation
        randomSeed={randomSeed}
        initialDarkMode={initialDarkMode}
        initialMenuState={initialMenuState}
      >
        {children}
      </Navigation>
    </MetaRequestContext.Provider>
  );
}
