"use client"
import React, {PropsWithChildren, useEffect, useState} from "react";

export interface UserBehaviourContextProps {
    userHasMovedMouse: boolean
}
const UserBehaviourContext
    = React.createContext<UserBehaviourContextProps>({
    userHasMovedMouse: false,
})

export const useUserBehaviour = () => React.useContext(UserBehaviourContext)

export default function WatchUserBehaviour({children}: PropsWithChildren) {
    
    const [
        userHasMovedMouse,
        setUserHasMovedMouse
    ] = useState<boolean>(false);
    
    useEffect(() => {
        const removeListener = () => {
            window.removeEventListener('mousemove', onMove)
        }
        const onMove = () => {
            setUserHasMovedMouse(true);
            removeListener();
        }
        if (!userHasMovedMouse) {
            window.addEventListener('mousemove', onMove)
            return removeListener;
        }
    })
    
    return (
        <UserBehaviourContext.Provider value={{userHasMovedMouse}}>
            {children}
        </UserBehaviourContext.Provider>
    )
    
}
